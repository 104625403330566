import { postRequest, getRequest } from './index'
// 销售合同分页
export const agentPurchaseContractPage = (data, successCallback, failureCallback) => {
  postRequest('/agentPurchaseContract/page', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 代采端垫资付款
export const agentPurchaseContractUpdateStatus = (data, successCallback, failureCallback) => {
  postRequest('/agentPurchaseContract/payApproval', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 合同管理详情
export const agentPurchaseContractDetail = (id, successCallback, failureCallback) => {
  getRequest(`/agentPurchaseContract/detail/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 合同管理详情
export const agentSettleDetail = (id, successCallback, failureCallback) => {
  getRequest(`/agentSettle/detail/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 合同管理详情
export const agentPurchaseContractSynPurContract = (data, successCallback, failureCallback) => {
  postRequest('agentPurchaseContract/synPurContract', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 代采端关联销售合同编号查询代采端销售合同详情
export const agentPurchaseContractFundsAndCustDetail = (id, successCallback, failureCallback) => {
  getRequest(`agentPurchaseContract/fundsAndCustDetail/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 入库完成
export const deliveryInfoWarehouseCompleted = (id, successCallback, failureCallback) => {
  getRequest(`/deliveryInfo/warehouseCompleted/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 录入ID结算
export const agentSettleGenerateSettlementBill = (contractId, successCallback, failureCallback) => {
  getRequest(`/agentSettle/generateSettlementBill/${contractId}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 获取销售合同的签署链接
export const agentPurchaseContractGetEsignUrl = (data, successCallback, failureCallback) => {
  postRequest(`agentPurchaseContract/getEsignUrl`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 获取销售合同的签署链接
export const agentPurchaseContractSignAudit = (data, successCallback, failureCallback) => {
  postRequest('agentPurchaseContract/signAudit', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 结算录入价格
export const agentSettlePriceRecording = (data, successCallback, failureCallback) => {
  postRequest('agentSettle/priceRecording', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 拒绝签署
export const agentPurchaseContractRefusedSign = (data, successCallback, failureCallback) => {
  postRequest(`/agentPurchaseContract/refusedSign`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 获取垫付记录
export const agentSettlePayAdvanceDetailSettle = (contractId, successCallback, failureCallback) => {
  getRequest(`/agentSettle/payAdvanceDetailSettle/${contractId}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
