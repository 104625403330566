import { postRequest, getRequest } from './index'
// 采购合同分页查询
export const agentPurchaseContractPage = (data, successCallback, failureCallback) => {
  postRequest('/agentPurchaseContract/page', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 采购合同详情查询
export const agentPurchaseContractDetail = (id, successCallback, failureCallback) => {
  getRequest('/agentPurchaseContract/detail/' + id, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 提货申请
export const agentPurchaseContractDeliveryApply = (data, successCallback, failureCallback) => {
  postRequest('/agentPurchaseContract/deliveryApply', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 采购合同签署
export const agentPurchaseContractSign = (id, successCallback, failureCallback) => {
  getRequest('/agentPurchaseContract/sign/' + id, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 采购合同签署2
export const agentPurchaseContractGetEsignUrl = (data, successCallback, failureCallback) => {
  postRequest(`agentPurchaseContract/getEsignUrl`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 采购合同签署回调
export const agentPurchaseContractSignCallback = (data, successCallback, failureCallback) => {
  postRequest('/agentPurchaseContract/signCallback', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 同步采购合同
export const agentPurchaseContractSynPurContract = (data, successCallback, failureCallback) => {
  postRequest('/agentPurchaseContract/synPurContract', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 采购合同签署逾期数据处理（定时任务处理）
export const agentPurchaseContractTimeOutHandle = (data, successCallback, failureCallback) => {
  postRequest('/agentPurchaseContract/timeOutHandle', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 销售合同状态变更垫付货款
export const agentPurchaseContractUpdateStatus = (data, successCallback, failureCallback) => {
  postRequest('agentPurchaseContract/updateStatus', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 获取待支付保证金列表
export const agentPurchaseContractToDepositList = (id, successCallback, failureCallback) => {
  postRequest(`/agentPurchaseContract/toDeposit/list/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 支付保证金接口2
export const agentPurchaseContractDepositPayment = (data, successCallback, failureCallback) => {
  postRequest(`/agentPurchaseContract/depositPayment`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 逾期支付
export const agentSettleOverduePayment = (data, successCallback, failureCallback) => {
  postRequest(`/agentSettle/overduePayment`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 拒绝签署
export const agentPurchaseContractRefusedSign = (data, successCallback, failureCallback) => {
  postRequest(`/agentPurchaseContract/refusedSign`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 检查是否可以发起提货申请
export const deliveryInfoCheckStartDeliveryApply = (data, successCallback, failureCallback) => {
  postRequest(`/deliveryInfo/checkStartDeliveryApply`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 获取提货申请的农行签署链接
export const agentPurchaseContractGetDeliveryApplyUrl = (data, successCallback, failureCallback) => {
  getRequest(`/agentPurchaseContract/getDeliveryApplyUrl/${data}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 获取支付保证金链接
export const agentPurchaseContractGetDepositPaymentUrl = (data, successCallback, failureCallback) => {
  postRequest(`/agentPurchaseContract/getDepositPaymentUrl`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 获取逾期付款支付链接
export const agentSettleGetOverduePaymentUrl = (data, successCallback, failureCallback) => {
  postRequest(`/agentSettle/getOverduePaymentUrl`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
