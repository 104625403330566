<template>
  <div class="CushionCapitalRecordPageBox">
    <el-dialog
      :visible.sync="visible"
      width="42%"
      title="垫资记录"
      :close-on-click-modal="false"
    >
      <Table
        :item-data="itemData"
        :list-data="listData"
      />
    </el-dialog>
  </div>
</template>

<script>
import Table from '@/components/Table'
import { agentSettlePayAdvanceDetailSettle } from '@/http/agentApi/salesContractManagement'
export default {
  components: { Table },
  props: {
    id: {
      default: '',
      type: [String, Number]
    }
  },
  data() {
    return {
      visible: false,
      itemData: [
        { label: '付款金额(元)', prop: 'payAmount' },
        { label: '计息金额(元)', prop: 'interestAmount' },
        { label: '审核时间', prop: 'auditTime' }
      ],
      listData: []
    }
  },
  watch: {
    visible: {
      handler(newVal, oldVal) {
        if (newVal) {
          if (newVal === oldVal) return
          if (this.listData.length === 0) this.getPaymentRecord()
          return
        }
        this.$emit('update:id', '')
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    // 获取垫付记录
    getPaymentRecord() {
      agentSettlePayAdvanceDetailSettle(this.id, res => {
        this.listData = [...res.data]
      })
    }
  }
}
</script>

